// @ts-strict-ignore
import { CircularProgress } from '@mui/material';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { TradeActions } from 'phoenix/constants/Trade';
import { TradeCannotSubmitReason } from 'phoenix/hooks/TradingHooks';
import { ApiData } from 'phoenix/models';
import { ApiOrderType } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { ApiPosition, OptionQuote, Order, TradeableSecurityType, TradeAction } from 'phoenix/redux/models';
import { AccountBuyingPower, AggregateBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { FormatNumber } from 'phoenix/util/FormatNumber';
import { GetSecurityTypeFromStoreV2 } from 'phoenix/util/IsMutualFund';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { XS } from 'phoenix/xstream/XS';
import React, { ReactChild } from 'react';
import { convertPovDisplayToFloorNote, POV_FLOORNOTES } from 'util/Utils';
import { GetTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { BuyingPowerDisplayProps } from './TradeFormComponents';
import { SnexWarning } from 'phoenix/models/SnexError';
import { SecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { useSnapQuoteStore } from 'store/SnapQuoteStore';


export function getBuyingPowerDisplayProps({
    loading,
    buyingPower,
    quantityOwned,
    positions,
    text,
    tradeAction,
    symbol
}: {
    loading: boolean;
    buyingPower: AccountBuyingPower | AggregateBuyingPower;
    quantityOwned: number;
    positions: ApiPosition[];
    text: Snex1LanguagePack;
    tradeAction: TradeAction;
    symbol: string;
    // trade: Partial<TradeRequest>;
}): Partial<BuyingPowerDisplayProps> {
    const isBuy = tradeAction === TradeActions.Buy;
    const unit = text.general.unit(symbol, quantityOwned, positions?.find((p) => p.productType)?.productType);
    const figure = isBuy ? FormatNumber.toMoney(buyingPower?.buyingPower) : `${quantityOwned} ${unit}`;
    const title = isBuy ? text.tradeTicket.input.buyingPower : text.tradeTicket.input.held(symbol);
    return {
        figure,
        loading,
        title
    };
}

export function algoStrategyToNoteAndNotHeld(lang: Snex1LanguagePack, strategy: string): { notHeld: boolean; floorNote: string } {
    const text = lang.tradeTicket.input;
    const viewModel = GetTradeTicketViewModel();

    if (strategy === text.twap) {
        return {
            notHeld: true,
            floorNote: 'TWAP'
        };
    }

    if (strategy === text.vwap) {
        return {
            notHeld: true,
            floorNote: 'VWAP'
        };
    }

    if (strategy === text.care) {
        return {
            notHeld: true,
            floorNote: viewModel?.algoNotes
        };
    }

    if (strategy === text.pov) {
        return {
            notHeld: true,
            floorNote: convertPovDisplayToFloorNote(viewModel?.algoNotes)
        };
    }

    return {
        notHeld: null,
        floorNote: ''
    };
}

export function noteAndNotHeldToAlgoStrategy(lang: Snex1LanguagePack, floorNote?: string, notHeld?: boolean): string {
    const text = lang.tradeTicket.input;

    const knownValues = [text.vwap, text.twap, 'GTX PRE-POST', 'GTX POST-MARKET'];
    const knownPovValues = POV_FLOORNOTES;

    if (notHeld && (!floorNote || !knownValues.includes(floorNote))) return text.care;
    if (!notHeld && (!floorNote || !knownValues.includes(floorNote))) return text.sor;
    if (notHeld && floorNote === text.vwap) return text.vwap;
    if (notHeld && floorNote === text.twap) return text.twap;
    if (notHeld && knownPovValues.includes(floorNote)) return text.pov;
    return '';
}


export const GetTradeTicketQuote = (symbol: string, useSnapQuote?: boolean): StandardQuote | (OptionQuote & { loading?: boolean }) => {
    const {snap} = useSnapQuoteStore();
    const secType = GetSecurityTypeFromStoreV2(symbol);
    return useSnapQuote? snap : secType === 'option' ? XS.OptionQuotes.use(symbol) : XS.Quotes.use(symbol)
};

export type GetSubmitButtonTextArgs = {
    assetClass: AssetClass;
    leg2Quantity: number;
    leg2Symbol: string;
    oneClickTrading?: boolean;
    orderType: ApiOrderType;
    secType: TradeableSecurityType;
    stopReason?: TradeCannotSubmitReason;
    symbol: string;
    text: Snex1LanguagePack;
    tradeAction: TradeAction;
    validateResponse: Partial<ApiData<Order | null> & { warnings: SnexWarning[] }>;
    meta?: SecurityMetadataV2;
};

// TODO: Coordinate with mobile to share all of the logic for this button text in phoenix
export function getSubmitButtonText({
    assetClass,
    leg2Quantity,
    leg2Symbol,
    oneClickTrading,
    orderType,
    secType,
    stopReason,
    symbol,
    text,
    tradeAction,
    validateResponse,
    meta
}: GetSubmitButtonTextArgs): ReactChild {
    const isTimeSpread = assetClass.derivative === 'time-spread';
    const offshoreMarketStart = new Date(new Date(new Date().setHours(2)).setMinutes(0)).toString();
    const offshoreMarketEnd = new Date(new Date(new Date().setHours(23)).setMinutes(59)).toString();
    const miscText = text.tradeTicket.misc;

    // prettier-ignore
    switch (stopReason) {
        case 'type-disabled': return miscText.securityTypeUnavailable(secType)
        case 'not-available': return miscText.notAvailableToTrade
        case 'no-nbbo': return `${miscText.noPricing}\n${miscText.noPricingCall}`
        case 'loading': return text.general.loading
        case 'bad-time-offshore-mutual-funds': return miscText.notInSession?.(offshoreMarketStart, offshoreMarketEnd)
        case 'beta-maintenance': return text.tradeTicket.betaMaintenanceHours
        case 'bad-time': return miscText.notInSessionGeneral
        case 'bad-tif': return miscText.badTimeInForce
        case 'gtx-pre-required': return miscText.premarketRequired
        case 'gtx-post-required': return miscText.orderExtendedHoursError(orderType?.toLowerCase());
        case 'options-level': return miscText.enableOptionsTradingNow
        case 'futures-account': return text.futuresOnboarding.openAccount
        case 'futures-account-onboarding-disabled': return text.futuresOnboarding.openAccountNotAllowed
        case 'equities-account': return miscText.createEquitiesAccount
        case 'restricted': return miscText.accountPending
        case 'upgrade-options-level': return text.tradeTicket.input.options.upgradeOptionsLevel
        case 'adjusted-option-position': return miscText.notInSessionGeneral
        case 'bad-time-to-edit': return miscText.notAvailableToEdit
    }

    if (!stopReason && validateResponse?.loading) return <CircularProgress color='inherit' style={{ height: '18px', width: '18px' }} />;

    if (oneClickTrading) return text.tradeTicket.input.tradeNow;
    if (leg2Symbol && leg2Quantity > 0) return text.tradeTicket.input.reviewTrade;
    if (isTimeSpread) {
        return getTimeSpreadSubmitButtonText({ symbol, text: text.tradeTicket, tradeAction, meta });
    }

    return text.tradeTicket.input.reviewAction({ action: tradeAction });
}

export function getTimeSpreadSubmitButtonText({
    symbol,
    text,
    tradeAction,
    meta
}: {
    symbol: string;
    text: Snex1LanguagePack['tradeTicket'];
    tradeAction: TradeAction;
    meta?: SecurityMetadataV2;
}): ReactChild {
    const fsym = new FuturesSymbol(symbol);

    // User is selecting "Buy," near month contract is long (+), far month contract is long (+)

    const buyIndication = meta?.timeSpread?.buyIndication;
    const buyingLong = tradeAction === TradeActions.Buy ? (buyIndication ? buyIndication === 'BuyNearSellFar' : fsym.nearMonthDirection === '+') : false;
    const sellingShort = tradeAction === TradeActions.Sell ? (buyIndication ? buyIndication === 'BuyFarSellNear' : fsym.nearMonthDirection === '-') : false;
    // The other two permutations (selling long, buying short) return the sell action text first and buy text 2nd
    const [firstLegAction, secondLegAction] = buyingLong || sellingShort ? [TradeActions.Buy, TradeActions.Sell] : [TradeActions.Sell, TradeActions.Buy];

    return (
        <div className='review-spread'>
            <span>
                {text.input.action[firstLegAction.toLowerCase()]} {QualifiedId.RemovePrefix(fsym.nearMonthContract)}
            </span>
            <span>
                {text.input.action[secondLegAction.toLowerCase()]} {QualifiedId.RemovePrefix(fsym.farMonthContract)}
            </span>
        </div>
    );
}
