// @ts-strict-ignore
import { useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { TradeTicketAccountDropdown } from 'components/AccountDropdown/TradeTicketAccountDropdown';
import { QuantityInputSplit } from 'components/InputSplit/QuantityInputSplit';
import { T } from 'phoenix/assets/lang/T';
import { TradeActions } from 'phoenix/constants/Trade';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { MutualFundAssetClass } from 'phoenix/models/AssetClasses/MutualFundAssetClass';
import { GetFundProfileAction } from 'phoenix/redux/actions/FundActions';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { IsOffshoreMutualFundByMetadataV2, toMoneyOpt2, UniqueBy } from 'phoenix/util';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../..';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { TradeInputHeader } from '../Headers/TradeInputHeader';
import { GetTradeTicketQuote } from '../Shared/helpers';
import { BuyingPowerDisplayV2, MarketPriceDisplay, TotalCostDisplay } from '../Shared/TradeFormComponents';
import { TradeInputValidationButton } from '../Shared/TradeInputValidationButton';
import { TradeTicketSection } from '../Shared/TradeTicketSection';
import { MutualFundTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { MinimumInvestmentDisplay } from './MinimumInvestmentDisplay';
import { useIsDelayedPricing } from 'phoenix/hooks/UseIsDelayedPricing';

export const FundTradeInputPage = React.memo(() => {
    const dispatch = useDispatch();
    const { load: loadPositions } = usePositionsStore();
    const viewModel = useTradeTicketViewModel<MutualFundTradeTicketViewModel>();
    // Use quantity input key to force a remount of the quantity input component
    // such as when using "Sell All" button to override user input
    const [quantityInputKey, setQuantityInputKey] = useState<number>(0);
    const { awaitingConfirmation, initialQuantity, modifyingOrder, quantityQualifier, symbol = '', setViewModel, tradeAction = 'Buy', quantity } = viewModel;
    const selectedAccountNumber = useSelectedAccountByAssetFamily(MutualFundAssetClass.family);
    const positions = usePositionsStore((s) => s.find({ qsi: symbol, accountNumber: selectedAccountNumber }));
    const text = useText((t) => t);
    const logo = useSnexStore((s) => s.logos.bySymbol[symbol ?? '']);
    const quote = GetTradeTicketQuote(symbol ?? '', useIsDelayedPricing(symbol));
    const generalText = useText((s) => s.general);
    const quantityLabel = quantityQualifier === 'Shares' ? generalText.fundShares(0) : text.tradeTicket.input.dollars;
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);
    const profile = useSnexStore((s) => s.funds.byQsi[symbol ?? '']?.profile);
    const { formatQuantity, getQuantityFormatOptions } = MutualFundAssetClass;
    const formatOptions = quantityQualifier === 'Shares' && getQuantityFormatOptions ? getQuantityFormatOptions(meta) : null; // Dollar based qty will use default money config
    const isOffshore = IsOffshoreMutualFundByMetadataV2(meta);
    const formatter =
        quantityQualifier === 'Shares' ? (number: number) => formatQuantity(number, meta) : (number: number) => toMoneyOpt2(number, { hideCurrencySymbol: true });

    const holdingAccounts = useMemo(() => {
        return tradeAction === 'Sell' ? new Set(UniqueBy(positions?.map((p) => p.accountNumber as string) || [], (x) => x as string)) : undefined;
    }, [tradeAction, positions]);

    useEffect(() => {
        loadPositions();
        dispatch(GetFundProfileAction(symbol ?? ''));
    }, [dispatch, loadPositions, symbol]);

    const handleFieldUpdate = (name: keyof MutualFundTradeTicketViewModel, value: MutualFundTradeTicketViewModel[keyof MutualFundTradeTicketViewModel]) => {
        setViewModel({ [name]: value });
    };

    const maybeHandleQuantityToggle = () => {
        const newQualifier = quantityQualifier === 'EvenDollar' ? 'Shares' : 'EvenDollar';

        const effectiveQuantity = quantity || initialQuantity;

        setViewModel({
            quantityQualifier: newQualifier,
            ...(effectiveQuantity ? { initialQuantity: undefined, quantity: undefined } : {})
        });
        setQuantityInputKey(quantityInputKey + 1); // Remount since potentially overriding previous user input
    };

    const handleAccountSelect = useCallback(() => setViewModel({ awaitingConfirmation: false }), [setViewModel]);

    // QuantityInputSplit only renders the "Edit" button if provided an onClick prop
    // Offshore MF do not allow "Shares" quantityQualifer when buying so disable that button in this case
    const handleQuantityToggle = isOffshore && tradeAction === 'Buy' ? undefined : maybeHandleQuantityToggle;

    // Switching an offshore MF to "Buy" restricts the quantityQualifier and resets the qty if switching back to dollars
    const handleActionChange = (value: ApiTradeAction) => {
        if (isOffshore && value === 'Buy' && quantityQualifier === 'Shares') setQuantityInputKey(quantityInputKey + 1);
    };

    const minimumInvestment = useMemo(() => {
        let value = profile?.data?.mutualFundProfile?.minimumInvestment;
        if (value === undefined) value = meta?.mutualFund?.minimumInitialInvestment;
        if (value === undefined) return 0;
        return value;
    }, [profile, meta]);

    return (
        <Flex column>
            {awaitingConfirmation ? (
                <TradeCancelHeader
                    logo={logo}
                    text={text.tradeTicket.input}
                    title={symbol as string}
                    onCancel={() => setViewModel({ awaitingConfirmation: false, validateResponse: undefined })}
                />
            ) : (
                <TradeInputHeader
                    customActions={!positions?.length ? [{ label: T((s) => s.tradeTicket.input.action.buy), value: TradeActions.Buy }] : undefined} // don't allow sell, if there are no positions
                    onActionChange={(v: ApiTradeAction) => handleActionChange(v)}
                    permittedOrderTypes={[]}
                />
            )}

            <TradeTicketSection noBorder style={{ paddingTop: 20, paddingBottom: 10 }}>
                <TradeTicketAccountDropdown
                    assetFamily={EquitiesAssetClass.family}
                    defaultToFirst={'if-only-one'}
                    disabled={modifyingOrder}
                    isByAssetClass
                    numberWhitelist={holdingAccounts}
                    onSelect={handleAccountSelect}
                    skipInitialSelect={modifyingOrder || !!selectedAccountNumber}
                    style={{ marginBottom: 15 }}
                    symbol={symbol}
                />
                <QuantityInputSplit
                    accountNumber={selectedAccountNumber}
                    arrows={true}
                    formatOptions={formatOptions}
                    formatter={formatter}
                    initialValue={initialQuantity}
                    key={quantityInputKey}
                    label={quantityLabel}
                    liquidateOnSellAll={true}
                    onBuySellAll={() => setQuantityInputKey(quantityInputKey + 1)}
                    onQuantityToggle={handleQuantityToggle}
                    onUnitChange={handleQuantityToggle}
                    onValueChange={(v) => handleFieldUpdate('quantity', Math.max(v, 0))}
                    quantityQualifierState={quantityQualifier}
                    showPosition={true}
                    showSellAll={true}
                    step={quantityQualifier === 'Shares' ? 1 : meta?.sizing?.minimumIncrementUsd || 0.01} // Used for shares only, 1 share per tick if shares, 1 cent per tick if dollars
                    symbol={symbol}
                    tradeAction={tradeAction}
                />
                <MarketPriceDisplay
                    ask={toMoneyOpt2(quote?.ask)}
                    bid={toMoneyOpt2(quote?.bid)}
                    hideAskBid
                    loading={quote?.loading}
                    price={toMoneyOpt2(quote?.price)}
                    text={text.tradeTicket.input}
                />
                <MinimumInvestmentDisplay loading={profile?.loading} minimumInvestment={minimumInvestment} />
            </TradeTicketSection>
            <TradeTicketSection style={{ paddingBottom: 20 }}>
                <TotalCostDisplay />
                <TradeInputValidationButton />
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                {/* set to buy to hide shares held per story #109048 */}
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} symbol={symbol} />
            </TradeTicketSection>
        </Flex>
    );
});
