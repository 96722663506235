// @ts-strict-ignore
import { T } from 'phoenix/assets/lang/T';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Flex } from '../..';
import { TradeInputHeader } from '../Headers/TradeInputHeader';
import { ImmediateExecutionAdvisory } from '../Shared/ImmediateExecutionAdvisory';

import { useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { TradeTicketAccountDropdown } from 'components/AccountDropdown/TradeTicketAccountDropdown';
import { QuantityInputSplit } from 'components/InputSplit/QuantityInputSplit';
import { OrderTypes } from 'phoenix/constants/Trade';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useQuantityHeldV2 } from 'phoenix/hooks/UseQuantityHeld';
import { useText } from 'phoenix/hooks/UseText';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { FormatNumber, SafeFormat } from 'phoenix/util';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { GetTradeTicketQuote } from '../Shared/helpers';
import { TimeInForceSelect } from '../Shared/TimeInForceSelect';
import { AlgoControl, BidAskButtons, BuyingPowerDisplayV2, MarketPriceDisplay, POVControl, PriceInputSplit, TotalCostDisplay } from '../Shared/TradeFormComponents';
import { TradeInputValidationButton } from '../Shared/TradeInputValidationButton';
import { TradeTicketSection } from '../Shared/TradeTicketSection';
import { EquityTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { useIsDelayedPricing } from 'phoenix/hooks/UseIsDelayedPricing';

export const EquityTradeInputPage = (): ReactElement => {
    const viewModel = useTradeTicketViewModel<EquityTradeTicketViewModel>();
    const {
        awaitingConfirmation,
        displayOrderType,
        initialLimitPrice,
        initialStopPrice,
        initialQuantity,
        limitPrice,
        modifyingOrder,
        orderType,
        setViewModel,
        stopPrice,
        symbol,
        timeInForce,
        tradeAction
    } = viewModel;
    // Use quantity input key to force a remount of the quantity input component
    // such as when using "Sell All" button to override user input
    const [quantityInputKey, setQuantityInputKey] = useState<number>(0);
    // Same as above but when Bid/Ask buttons are clicked to "reset" price
    const [limitInputKey, setLimitInputKey] = useState<number>(0);
    const [stopInputKey, setStopInputKey] = useState<number>(0);
    const isOnClose = ['loc', 'moc'].includes(displayOrderType);
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);
    const quote = GetTradeTicketQuote(symbol, useIsDelayedPricing(symbol));
    const text = useText((t) => t);
    const logo = useSnexStore((s) => s.logos.bySymbol[symbol]);
    const userInfo = useSnexStore((s) => s.user?.myInfo.data);
    const hasAdvancedRouting = useMemo(() => userInfo?.hasAdvancedRouting, [userInfo]);
    const [currentMarketSegment] = useMarketTimeSegmentV2();
    const selectedAccountNumber = useSelectedAccountByAssetFamily(EquitiesAssetClass.family);
    const showNotes = userInfo?.role === 'admin';
    const secId = QualifiedSecurityId.Parse(symbol);
    const held = useQuantityHeldV2(secId, selectedAccountNumber);
    const quantityFormatter = (number: number) => FormatNumber.toCommas(number, true, 0);
    const priceFormatter = (price: number) => EquitiesAssetClass?.formatPrice(price, meta);
    const isGtx = ['GTXPre', 'GTXPost', 'NTE'].includes(orderType);
    const showAlgo = hasAdvancedRouting && !isOnClose && currentMarketSegment === 'open' && !(modifyingOrder && isGtx); // Do not display algo fields when modifyingOrdering a gtx pre/post order

    const handleFieldUpdate = (name: keyof EquityTradeTicketViewModel, value: EquityTradeTicketViewModel[keyof EquityTradeTicketViewModel]) => {
        const newState = { [name]: value };

        // If selling, and changing qty to exceed currently held position, reset TIF; user must manually reselect "Day"
        if (name === 'quantity' && tradeAction === 'Sell' && timeInForce !== 'Day' && value > held) {
            newState.timeInForce = null;
        }

        setViewModel(newState);
    };

    const handleAccountSelect = useCallback(() => setViewModel({ awaitingConfirmation: false }), [setViewModel]);

    return (
        <Flex column>
            {awaitingConfirmation ? (
                <TradeCancelHeader
                    logo={logo}
                    text={text.tradeTicket.input}
                    title={symbol}
                    onCancel={() => setViewModel({ awaitingConfirmation: false, validateResponse: null })}
                />
            ) : (
                <TradeInputHeader canSellIfNotHeld={true} />
            )}
            <TradeTicketSection noBorder style={{ paddingTop: 20, paddingBottom: 10 }}>
                <TradeTicketAccountDropdown
                    assetFamily={EquitiesAssetClass.family}
                    balanceType={tradeAction?.toLowerCase()}
                    defaultToFirst={'if-only-one'}
                    disabled={modifyingOrder}
                    isByAssetClass
                    onSelect={handleAccountSelect}
                    skipInitialSelect={modifyingOrder || !!selectedAccountNumber}
                    style={{ marginBottom: 15 }}
                    symbol={symbol}
                />
                {['stop', 'stoplimit'].includes(orderType) && (
                    <>
                        <PriceInputSplit
                            formatter={EquitiesAssetClass.formatPrice}
                            formatOptions={EquitiesAssetClass.getPriceFormatInfo()?.moneyFormatOptions}
                            initialValue={initialStopPrice}
                            key={stopInputKey}
                            label={text.tradeTicket.input.stopPrice}
                            onValueChange={(v) => handleFieldUpdate('stopPrice', v)}
                        />
                        <BidAskButtons
                            bid={quote?.bid}
                            ask={quote?.ask}
                            askLabel={`${text.tradeTicket.input.ask}: ${EquitiesAssetClass.formatPrice(quote?.ask)}`}
                            bidLabel={`${text.tradeTicket.input.bid}: ${EquitiesAssetClass.formatPrice(quote?.bid)}`}
                            onValueChange={(v) => {
                                setViewModel({
                                    initialStopPrice: v,
                                    stopPrice: undefined
                                });
                                setStopInputKey(stopInputKey + 1);
                            }}
                        />
                    </>
                )}
                {['limit', 'stoplimit'].includes(orderType) && (
                    <>
                        <PriceInputSplit
                            formatter={EquitiesAssetClass.formatPrice}
                            formatOptions={EquitiesAssetClass.getPriceFormatInfo()?.moneyFormatOptions}
                            initialValue={initialLimitPrice}
                            key={limitInputKey}
                            label={orderType === 'limit' ? text.tradeTicket.input.limitPrice : text.tradeTicket.input.stopLimitPrice}
                            onValueChange={(v) => handleFieldUpdate('limitPrice', v)}
                        />
                        <BidAskButtons
                            bid={quote?.bid}
                            ask={quote?.ask}
                            askLabel={`${text.tradeTicket.input.ask}: ${EquitiesAssetClass.formatPrice(quote?.ask)}`}
                            bidLabel={`${text.tradeTicket.input.bid}: ${EquitiesAssetClass.formatPrice(quote?.bid)}`}
                            onValueChange={(v) => {
                                setViewModel({
                                    initialLimitPrice: v,
                                    limitPrice: undefined
                                });
                                setLimitInputKey(limitInputKey + 1);
                            }}
                        />
                    </>
                )}
                <QuantityInputSplit
                    accountNumber={selectedAccountNumber}
                    arrows={true}
                    formatter={quantityFormatter}
                    initialValue={initialQuantity}
                    key={quantityInputKey}
                    label={T((t) => t.general.fundShares(0))}
                    liquidateOnSellAll={false}
                    onBuySellAll={() => setQuantityInputKey(quantityInputKey + 1)}
                    onValueChange={(v) => handleFieldUpdate('quantity', v)}
                    showPosition={true}
                    showSellAll={true}
                    step={1} // Used for shares only, 1 share per tick
                    symbol={symbol}
                    tradeAction={tradeAction}
                />

                {orderType !== 'market' && !isOnClose ? <TimeInForceSelect onValueChange={(v) => handleFieldUpdate('timeInForce', v)} /> : null}
                <MarketPriceDisplay
                    ask={priceFormatter(quote?.ask)}
                    bid={priceFormatter(quote?.bid)}
                    hideAskBid={orderType !== 'market'}
                    loading={quote?.loading}
                    price={priceFormatter(quote?.price)}
                    text={text.tradeTicket.input}
                />
            </TradeTicketSection>
            {orderType !== OrderTypes.market && (
                <ImmediateExecutionAdvisory
                    action={tradeAction}
                    orderType={displayOrderType}
                    text={text.tradeTicket.input.advisories}
                    {...{ limitPrice, quote, stopPrice }}
                />
            )}
            <TradeTicketSection style={{ paddingBottom: 20 }}>
                <TotalCostDisplay />
                {showAlgo && <AlgoControl />}
                <POVControl isVisible={showAlgo && viewModel.algoStrategy === T((t) => t.tradeTicket.input.pov) && !showNotes} modifyingOrder={modifyingOrder} />
                <TradeInputValidationButton />
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                {/* set action to buy to force buying power to show always on equity #108615 */}
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} meta={meta} symbol={symbol} />
            </TradeTicketSection>
        </Flex>
    );
};
