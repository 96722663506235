import { useSnexStore } from "phoenix/hooks/UseSnexStore"
import { useIsDelayedPricing } from "phoenix/hooks/UseIsDelayedPricing"
import { getTradeSymbol } from "components/TradeTicket/Store/useTradeTicketViewModel"

export const UseOneClickTrading = (): boolean => {
    const oneClick = useSnexStore(s => s.user.myInfo?.data?.oneClickTrading)
    const symbol = getTradeSymbol()
    const isDelayed = useIsDelayedPricing(symbol)
    return !!(oneClick && !isDelayed)
}
