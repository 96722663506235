import { getTradeSymbol } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { Urls } from 'phoenix/constants';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { OptionQuote } from 'phoenix/redux/models';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { create } from 'zustand';

type SnapQuoteStore = {
    loading: boolean;
    snap: Partial<StandardQuote> | Partial<OptionQuote>;
    refreshSnapQuote: () => void;
}

export const useSnapQuoteStore = create<SnapQuoteStore>((set) => ({
    loading: false,
    snap: {},
    refreshSnapQuote: async () => {
        set((s) => ({ ...s, loading: true }))
        const symbol = getTradeSymbol();
        const snap = await SnexAxios.ApiGet<StandardQuote | OptionQuote>(Urls.securities.core.getSnapQuote(symbol)).run();
        set((s) => ({ ...s, loading: false, snap }))
    }
}))