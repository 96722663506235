import { Typography } from "@mui/material"
import { useColors } from "hooks/UseColors"
import { SafeFormat } from "phoenix/util"
import React from "react"
import { useSnapQuoteStore } from "store/SnapQuoteStore"

export const AsOfTimestamp: React.FC<{style?: React.CSSProperties, wrapStyle?: React.CSSProperties}> = ({style, wrapStyle}) => {
    const colors = useColors()
    const {loading} = useSnapQuoteStore()

    if(loading) return null;

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', ...wrapStyle}}>
        <Typography variant='h6' style={{padding: '2px 8px', fontSize: '12px', lineHeight: '1.25', borderRadius: 3, color:colors.black, backgroundColor: '#ffc107', textAlign: 'right', ...style}}>
            Price as of {SafeFormat(new Date(), 'dd MMM yyyy hh:mm:ss bb')}          
        </Typography>
</div>
    )

}