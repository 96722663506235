// @ts-strict-ignore
import { CryptoSymbol } from 'phoenix/redux/models/Crypto/CryptoSymbol';
import { FuturesSymbol } from '../../phoenix/redux/models/Futures/FuturesSymbol';
import { ApiPosition, OptionSymbol, Order, TaxlotGroup, TaxlotItem, TradeRequest } from '../redux/models';
import { SnexOrderUpdateMessagePayload } from '../redux/models/Messages/SnexOrderUpdateMessagePayload';
import { UnquotedPosition } from '../redux/models/Positions/UnquotedPosition';
import { QualifiedId } from 'phoenix/util/QualifiedId';

export type SecurityIdType = 'Symbol' | 'OptionSymbol' | 'Isin' | 'Cusip' | 'SecurityNumber' | 'Future' | 'Unknown' | 'FutureOptionSymbol' | 'Crypto';

/** @deprecated Please use the asset class functions where avail. We intend to remove this in a future tech debt */
export class QualifiedSecurityId {
    src: string;
    type: SecurityIdType;
    id: string | null;
    optionMeta: OptionSymbol | null;

    // Formats:
    // AAPL            <-- For Symbols or Options
    // I:US123545678AB <-- For ISINs
    // C:123545678AB   <-- For CUSIPs
    // B:38441799      <-- For Beta Security Number
    // F:ZCZ22         <-- For futures contracts
    // F:ESM22220613C04175000@F:OE2AM22 C4175 <-- futures options contract with OSI + CQG formats
    static Parse(src: string): QualifiedSecurityId {
        const qsi = new QualifiedSecurityId();
        const isOption = OptionSymbol.IsOptionSymbol(src);
        qsi.optionMeta = isOption ? new OptionSymbol(src) : null;

        qsi.src = src;
        if (!src || typeof src !== 'string') {
            qsi.type = 'Unknown';
        } else if ((src.indexOf?.(':') || -1) === -1) {
            qsi.type = isOption ? 'OptionSymbol' : 'Symbol';
            qsi.id = src;
        } else {
            const flag = src[0];
            switch (flag) {
                case 'I':
                    qsi.type = 'Isin';
                    break;
                case 'C':
                    qsi.type = 'Cusip';
                    break;
                case 'B':
                    qsi.type = 'SecurityNumber';
                    break;
                case 'F':
                    qsi.type = isOption ? 'FutureOptionSymbol' : 'Future';
                    break;
                case 'X':
                    qsi.type = 'Crypto';
                    break;
                default:
                    qsi.type = 'Unknown';
                    break;
            }
            // Futures and Cryptos IDs
            qsi.id = flag === 'F' || flag === 'X' ? src : src.substr(2);
        }
        return qsi;
    }

    static IsSymbol(src: string): boolean {
        return !/^(I|C|B):/.test(src);
    }

    toString(): string {
        switch (this.type) {
            case 'Isin':
                return QualifiedSecurityId.IsSymbol(this.id) ? `I:${this.id}` : this.id;
            case 'Cusip':
                return QualifiedSecurityId.IsSymbol(this.id) ? `C:${this.id}` : this.id;
            case 'SecurityNumber':
                return QualifiedSecurityId.IsSymbol(this.id) ? `B:${this.id}` : this.id;
            case 'OptionSymbol':
                return new OptionSymbol(this.id).osiSymbol;
            default:
                return this.id;
        }
    }

    static Unkown() {
        const qsi = new QualifiedSecurityId();
        qsi.type = 'Unknown';
        return qsi;
    }

    static FromRaw(type: SecurityIdType | string, id: string): QualifiedSecurityId {
        const qsi = new QualifiedSecurityId();
        qsi.type = <SecurityIdType>type;
        qsi.id = id;
        if (type.includes('OptionSymbol')) qsi.optionMeta = new OptionSymbol(id);
        // If it's a prefixable type, and missing its prefix, add the prefix to the ID
        if (['Isin', 'Cusip', 'SecurityNumber'].includes(type) && this.IsSymbol(id)) qsi.id = qsi.toString();
        return qsi;
    }

    static FromPosition(position: ApiPosition): QualifiedSecurityId {
        // Note: some systems put the underlying symbol in the position.symbol field, while others put the full OSI/OCC/CQG/etc. option symbol
        // in the position.symbol field. We accommodate both below.

        if (!position) return QualifiedSecurityId.FromRaw(<SecurityIdType>'Unknown', null);

        const optSym = OptionSymbol.IsOptionSymbol(position?.symbol)
            ? new OptionSymbol(position?.symbol)
            : new OptionSymbol(position?.secMasterOptionSymbol || position?.description, position?.symbol);

        const isFuture = FuturesSymbol.IsFuturesSymbol(position?.symbol);
        const isCrypto = CryptoSymbol.IsCryptoSymbol(position?.symbol);

        if (optSym.isOption) {
            return isFuture
                ? QualifiedSecurityId.FromRaw('FutureOptionSymbol', position?.secMasterOptionSymbol)
                : QualifiedSecurityId.FromRaw('OptionSymbol', optSym.osiSymbol);
        }

        const info = [
            { type: 'Future', id: isFuture && position.symbol },
            { type: 'Crypto', id: isCrypto && position.symbol },
            { type: 'Symbol', id: this.IsSymbol(position.symbol) && position.symbol },
            { type: 'Isin', id: position.isin },
            { type: 'Cusip', id: position.cusip },
            { type: 'SecurityNumber', id: position.securityNumber }
        ].find((x) => !!x.id);
        return QualifiedSecurityId.FromRaw(<SecurityIdType>(info?.type || 'Unknown'), info?.id);
    }

    static FromUnquotedPosition(position: UnquotedPosition): QualifiedSecurityId {
        const sym = position.symbol;
        const info = [
            { type: 'Future', id: FuturesSymbol.IsFuturesSymbol(sym) && sym },
            { type: 'Crypto', id: CryptoSymbol.IsCryptoSymbol(sym) && sym },
            { type: 'OptionSymbol', id: OptionSymbol.IsOptionSymbol(sym) && sym },
            { type: 'Symbol', id: this.IsSymbol(sym) && sym },
            { type: 'Isin', id: position.isin },
            { type: 'Cusip', id: position.cusip },
            { type: 'SecurityNumber', id: position.securityNumber }
        ].find((x) => !!x.id);
        return QualifiedSecurityId.FromRaw(<SecurityIdType>(info?.type || 'Unknown'), info?.id);
    }

    static FromTradeRequest(trade: Partial<TradeRequest>): QualifiedSecurityId {
        const id = trade?.securityId;
        let type = 'Unknown';
        switch (trade?.securityIdType) {
            case 'cusip':
                type = 'Cusip';
                break;
            case 'isin':
                type = 'Isin';
                break;
            case 'secNo':
                type = 'SecurityNumber';
                break;
            case 'symbol':
                type = trade.option ? 'OptionSymbol' : 'Symbol';
                break;
        }
        return QualifiedSecurityId.FromRaw(<SecurityIdType>type, id);
    }

    static FromOrder(order: Order): QualifiedSecurityId {
        if (!order) return QualifiedSecurityId.FromRaw('Unknown', '');

        const optSym = new OptionSymbol(order?.symbol);
        const isFuture = FuturesSymbol.IsFuturesSymbol(order?.symbol);

        if (optSym.isOption) {
            return isFuture ? QualifiedSecurityId.FromRaw('FutureOptionSymbol', order?.symbol) : QualifiedSecurityId.FromRaw('OptionSymbol', optSym.osiSymbol);
        }

        const match = [
            [order.symbol, 'Symbol'],
            [order.cusip, 'Cusip'],
            [order.isin, 'Isin'],
            [order.securityNumber, 'SecurityNumber']
        ].find((p) => !!p[0]) || ['', 'Unknown'];
        return QualifiedSecurityId.FromRaw(<SecurityIdType>match[1], match[0]);
    }

    static FromOrderUpdate(order: SnexOrderUpdateMessagePayload): QualifiedSecurityId {
        if (!order) return QualifiedSecurityId.FromRaw('Unknown', '');
        const match = [
            [order.symbol, 'Symbol'],
            [order.securityNumber, 'SecurityNumber']
        ].find((p) => !!p[0]) || ['', 'Unknown'];
        return QualifiedSecurityId.FromRaw(<SecurityIdType>match[1], match[0]);
    }

    public MatchesPosition(position: ApiPosition): boolean {
        if (!position) return null;
        if (this.type === 'FutureOptionSymbol') {
            return position?.secMasterOptionSymbol === this.id;
        }
        if (this.type === 'OptionSymbol') {
            return (position?.optionOsiSymbol || position?.secMasterOptionSymbol)?.replace(/\s*/g, '') === this.id.replace(/\s*/g, '');
        } else if (position?.optionOsiSymbol || position?.secMasterOptionSymbol) {
            // If this is an option position, but we aren't looking for an option, quit it!
            return false;
        }
        switch (this.type) {
            case 'Cusip':
                return position.cusip === this.id;
            case 'Isin':
                return position.isin === this.id;
            case 'SecurityNumber':
                return position.securityNumber === this.id;
            case 'Crypto':
                return position.symbol === this.src;
            case 'Symbol':
            default:
                return position.symbol === this.id;
        }
    }

    public MatchesOrder(order: Order, includeDerivatives?: boolean): boolean {
        switch (this.type) {
            case 'Cusip':
                return order.cusip === this.id;
            case 'Isin':
                return order.isin === this.id;
            case 'SecurityNumber':
                return order.securityNumber === this.id;
            case 'Future':
            case 'Symbol':
                return order.symbol === this.id || (includeDerivatives && order.symbol?.includes(this.id));
            default:
                return order.symbol === this.id;
        }
    }

    public MatchesTaxLot(taxl: TaxlotGroup): boolean {
        switch (this.type) {
            case 'Cusip':
                return [this.id, this.id.replace(/^C:/, '')].includes(taxl.cusip);
            case 'Isin':
                return [taxl.isin, `I:${taxl.isin}`].includes(this.id);
            case 'SecurityNumber':
                return [this.id, this.id.replace(/^B:/, '')].includes(taxl.securityNumber);
            case 'OptionSymbol':
                return this.optionMeta && taxl.items.some((i) => this.optionMeta?.osiSymbol === (i.optionOsiSymbol || i.optionSymbol?.replace(/\s*/g, '')));
            case 'Symbol':
                // Taxlots endpoint returns symbols in the format 'BRK/B' for BRK.B, so we need to replace the '/' with a '.' to match
                return taxl.symbol === this.id.replace('.', '/') && taxl.items.some((i) => !i.optionSymbol);
            default:
                return false;
        }
    }

    public MatchesTaxLotItem(taxl: TaxlotItem): boolean {
        switch (this.type) {
            case 'Cusip':
                return [this.id, this.id.replace(/^C:/, '')].includes(taxl.cusip);
            case 'Isin':
                return [taxl.isin, `I:${taxl.isin}`].includes(this.id);
            case 'SecurityNumber':
                return [this.id, this.id.replace(/^B:/, '')].includes(taxl.securityNumber);
            case 'OptionSymbol':
                return this.optionMeta && this.optionMeta?.osiSymbol === taxl.optionOsiSymbol;
            case 'Symbol':
                return taxl.symbol === this.id && !taxl.optionSymbol;
            default:
                return false;
        }
    }

    public ToOrderCompatibleSecurityType(): 'symbol' | 'cusip' | 'isin' | 'secNo' {
        switch (this.type) {
            case 'Cusip':
                return 'cusip';
            case 'OptionSymbol':
            case 'Symbol':
                return 'symbol';
            case 'Isin':
                return 'isin';
            case 'SecurityNumber':
                return 'secNo';
        }
    }

    public ToSecurityMasterCompatibleType(): 'Symbol' | 'Cusip' | 'Isin' | 'SecurityNumber' {
        switch (this.type) {
            case 'Cusip':
            case 'Isin':
            case 'SecurityNumber':
            case 'Symbol':
                return this.type;
            case 'OptionSymbol':
            default:
                return 'Symbol';
        }
    }
}
